import React from 'react';
import APILink from './APILink.js'

const Sidebar = props => {
    let organizationConfig = props.organizationConfig
    let apiLinks = []
    if (props.definitionList === null || props.definitionList === undefined) {
        props.getOrganizationData(organizationConfig.orgData.orgName)
    } else {
        for (let i = 0; i < props.definitionList.length; i++) {
            apiLinks.push(
                <APILink
                    key={i}
                    apiLinkData={props.definitionList[i]}
                    updateDefinitionLink={props.updateDefinitionLink}
                />
            )
        }
    }

  return (
    <div className="side-bar">
        <div className="side-bar-header">
            <h1>{organizationConfig.orgData.displayName}</h1>
        </div>
        <div className="side-bar-body">
            <h3>API DOCS</h3>
            {apiLinks}
            <div className="api-link">
                <a href="/graphql">Graphql Interface</a>
            </div>
        </div>
    </div>
  )
}

export default Sidebar;
